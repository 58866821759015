html, body {
  margin: 0;
  font-size: 16px;
  min-width: 1400px
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  font-family: Helvetica;
}

@font-face {
  font-family: 'Impact';
  src: url(../static/font/Impact.ttf);
}

@font-face {
  font-family: 'Helvetica';
  src: url(../static/font/Helvetica.ttc);
}

@font-face {
  font-family: 'Arial Unicode';
  src: url(../static/font/ArialUnicode.ttf);
}

input, button {
  outline: none;
  border: none;
  cursor: pointer;
}

body {
  min-height: 797px;
  height: 100vh;
  background-position-x: 270px;
  background-size: 1400px 893px;
}
body.gradient {
  background: radial-gradient(#022B87 0%, #002266 100%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}


.loading-big {
	width: 50px;
	height: 50px;
	border: 6px solid #ccc;
	border-bottom: 6px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
  -o-animation:load 1.1s infinite linear;
  z-index: 10;
}
.loading {
	width: 16px;
	height: 16px;
	border: 2px solid #fff;
	border-bottom: 2px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
	-o-animation:load 1.1s infinite linear;
  	z-index: 300;
}

.loading-base {
	width: 16px;
	height: 16px;
	border: 3px solid #fff;
	border-bottom: 3px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
	-o-animation:load 1.1s infinite linear;
  z-index: 300;
}
@keyframes load {
	from {
    transform:rotate(0deg);
    -ms-transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
    -ms-transform:rotate(360deg);
  }
}

.weak-hint-main {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 50px;
  margin-left: -200px;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  animation: myfirst 3s ease-in-out 0s 1 alternate forwards;
  z-index: 300;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
}
@keyframes myfirst {
  0% {
      top: -10%;
      opacity: 0;
  }
  20% {
      opacity: 1;
      top: 20%;
  }
  80% {
      opacity: 1;
      top: 20%;
  }
  100% {
      top: -10%;
      opacity: 0;
  }
}